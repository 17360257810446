import PropTypes from 'prop-types';

export const COLOR_TYPE = Object.freeze({
  black: 'black',
  white: 'white',
  dialed: 'dialed',
  grey: 'grey',
  chill: 'chill',
  zzzz: 'zzzz',
  fresh: 'fresh',
  lively: 'lively',
  fullColorGradient: 'full-color-gradient',
  threeColorGradient: 'three-color-gradient',
  text: 'text',
});

const colorTypes = {
  color: PropTypes.oneOf(Object.values(COLOR_TYPE)),
};

export default colorTypes;
