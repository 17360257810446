import PropTypes from 'prop-types';

import { COLOR_TYPE } from './colorTypes';

export const buttonColors = Object.values(COLOR_TYPE).filter(
  color => color !== COLOR_TYPE.fullColorGradient
    && color !== COLOR_TYPE.threeColorGradient
    && color !== COLOR_TYPE.text,
);

export const buttonDefaultProps = {
  activeColor: COLOR_TYPE.fullColorGradient,
  children: null,
  className: null,
  color: COLOR_TYPE.zzzz,
  hasColorBack: false,
  hasMinWidth: false,
  hasBorder: false,
  hasUnderline: false,
  isTransparent: false,
  href: null,
  onClick: null,
  style: null,
  to: null,
};

const buttonTypes = {
  activeColor: PropTypes.oneOf(Object.values(COLOR_TYPE)),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(buttonColors),
  hasColorBack: PropTypes.bool,
  hasMinWidth: PropTypes.bool,
  hasBorder: PropTypes.bool,
  rollOverStyle: PropTypes.string,
  hasUnderline: PropTypes.bool,
  href: PropTypes.string,
  isTransparent: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  to: PropTypes.string,
};

export default buttonTypes;