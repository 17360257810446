import React from 'react';
import { navigate } from 'gatsby';
import classNames from 'classnames';

import buttonTypes, { buttonDefaultProps } from '../../types/buttonTypes';

import './Button.scss';

export default class Button extends React.Component {
  static propTypes = buttonTypes;

  static defaultProps = buttonDefaultProps;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    isClicked: false,
  };

  // tid = null; // {TimeoutID}
  componentWillUnmount() {
    clearTimeout(this.tid);
  }

  onClick = (event) => {
    event.preventDefault();
    const { isClicked } = this.state;
    if (isClicked) {
      return;
    }
    const {
      hasColorBack, href, onClick, to,
    } = this.props;
    this.setState({ isClicked: true });
    if (!href && !onClick && !to && !hasColorBack) {
      return;
    }

    this.tid = setTimeout(() => {
      if (to) {
        navigate(to);
        document.body.classList.remove('noScroll');
      } else if (href) {
        window.open(href, '_self');
      } else {
        this.setState({ isClicked: false }, () => {
          if (onClick) {
            onClick();
          }
        });
      }
    }, 100);
  };

  render() {
    const {
      activeColor,
      children,
      className,
      color,
      hasColorBack,
      hasMinWidth,
      hasBorder,
      hasUnderline,
      isTransparent,
      rollOverStyle,
      onClick,
      style,
      ...props
    } = this.props;
    const { isClicked } = this.state;
    return (
      <div
        className={classNames(
          'Button',
          `is-${color}`,
          isClicked && 'is-clicked',
          isTransparent && 'is-transparent',
          hasMinWidth && 'has-no-min-width',
          hasBorder && 'has-border',
          hasColorBack && 'has-color-back',
          hasUnderline && 'has-underline',
          `rollover-${rollOverStyle || 'opacity'}`,
          className,
        )}
        style={{
          background: isTransparent ? 'transparent' : `var(--${color})`,
          ...style,
        }}
        {...props}
      >
        <div
          className="Button--bg"
          style={{
            background: `var(--${activeColor})`,
            ...style,
          }}
        />
        <div className="Button--text">{children}</div>
        <button aria-label="click button" className="Button--area" onClick={this.onClick} type="button" />
      </div>
    );
  }
}
